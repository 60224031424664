import React, { Component } from 'react'
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';
import { InputContainer2, Body, InfoBox, TokenSearchContainer, InputContainer, FullContainer, Container12, HeaderContainer, OutputContainer, Hr, Vr, InputDiv, RightSide } from '../../components/sharedComponents/styledComponents'
import { pageTransition, pageVariants} from '../../components/sharedComponents/FramerMotionPrompts/FramerMotionPrompts'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import coinIcon from '../../components/Assets/images/coinIcon.svg'
import AdComponent from '../../components/sharedComponents/AdComponent/AdComponent'

export default class FractionalNodes extends Component<{}, { rewards1: number, rewards2: number, rewards3: number, rewards4: number, value1: string, tokenImage1: string, displayPrice1: string, 
                                                    tokenName1: string, color1: string, list1: any[], list2: any[], data:any[],
                                                    tier1name: string,tier2name: string,tier3name: string,tier4name: string}> {
    
    constructor(props: any){
        super(props)
        this.handleChange = this.handleChange.bind(this); 
        this.state = {
            rewards1: 0,
            rewards2: 0,
            rewards3: 0,
            rewards4: 0,
            tier1name: 'Tier 1',
            tier2name: 'Tier 2',
            tier3name: 'Tier 3',
            tier4name: 'Tier 4',
            value1 : '',
            tokenImage1: coinIcon,
            displayPrice1: 'N/A',        
            tokenName1: 'Token',
            color1: 'gray',
            list1: [],
            list2: [],
            data: []
        };
      }
    
    handleChange(event: any) {
        this.setState({ ...this.state, [event.target.name]: event.target.value }, this.updateData);
    }

    updateData(){
        var data = []
        console.log('getting graph')
        if (this.state.rewards1 > 16 || this.state.rewards1 > 16 || this.state.rewards1 > 16 || this.state.rewards1 > 16){
            return
        } else{
            for (let i=0; i<150; i++){
                var yieldPercent1 = (1*((1+(this.state.rewards1/100))**i) - 1)/1 * 100 
                var yieldPercent2 = (1*((1+(this.state.rewards2/100))**i) - 1)/1 * 100 
                var yieldPercent3 = (1*((1+(this.state.rewards3/100))**i) - 1)/1 * 100 
                var yieldPercent4 = (1*((1+(this.state.rewards4/100))**i) - 1)/1 * 100 
                data.push({day: i, tier1: yieldPercent1.toFixed(2), tier2: yieldPercent2.toFixed(2), tier3: yieldPercent3.toFixed(2), tier4: yieldPercent4.toFixed(2)})
                
            }
            this.setState({...this.state, data: data})
        }
    }
    
    
   
    async fetchData1() {
        try {
            const result = await axios.get(`https://api.coingecko.com/api/v3/search?query=`)
            console.log('list updated')
            return result.data.coins;
        } catch (error) {
            console.error(error);
            
        }
    }
    handleSearch1(value: any){
        this.setState({ ...this.state, value1: value})
        console.log('search')
    }

    async UNSAFE_componentWillMount() {
        let mounted = true;
        await this.fetchData1()
        .then((items : any[]) => {
            if(mounted) {
            this.setState({ list2: items })
            let optionList: any[] = []
            items.forEach(x => optionList.push({name : x.name, value: x.id, key: x.id, id: x.symbol}))
            this.setState({...this.state, list1: optionList})
            }
        })
        return () => mounted = false;
    }
    
    GetImageAndPrice1(value: string) {
            for (var i = 0; i < this.state.list2.length; i++) {
                if (this.state.list2[i].id == value) {
                    // An item was selected from the list!
                    // yourCallbackHere()
                    console.log('image')
                    this.getPrice1(i);
                    break;
                } 
            }
        
    }
    async getPrice1(i:number){
        var id = this.state.list2[i].id
        try {
            const result = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`)
            console.log('fetch')
            this.setState( { displayPrice1 : '$' + result.data[id]['usd'], 
                            tokenName1: this.state.list2[i].symbol,
                            tokenImage1: this.state.list2[i].large,
                            color1: 'black' } )
        } catch (error) {
            console.error(error);
        }  
    } 
    
    render(){
        return (
            
            <Body className='calc-body'>
                <motion.div 
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
                transition={pageTransition}
                
                >
                <FullContainer className='full-container'>
                    <div className='header'>
                    <HeaderContainer className="header">
                        <img src={ this.state.tokenImage1 } width='100px' alt="altas-logo" />

                        
                        <div className="headerContent">
                            <h3 className="pricebot">{this.state.tokenName1} Price</h3>
                            <h1 id="pricehere" style={{color: this.state.color1}}>{this.state.displayPrice1}</h1>
                        </div>
                        <div className='headerContent'>
                            
                            <h3>Constraints</h3>
                            <h1>ROI {'<'} 16%</h1>
                        </div>
                        
                    </HeaderContainer>
                    </div>
                    <div className='title'>
                        <h2>Fractional Nodes</h2>
                        <img style={{width: '45px'}} src={this.state.tokenImage1} />
                    </div>
                    <Hr></Hr>
                    <Container12 className="contents">    
                        <InputContainer className="container inputs">
                            
                            <InputDiv className="input">
                                <h3>Search Token</h3>
                                <SelectSearch
                                    value={this.state.value1}
                                    options={this.state.list1}
                                    search
                                    onChange={x => {this.GetImageAndPrice1(String(x)); this.handleSearch1(String(x))}}
                                    placeholder="Find Coin"
                                    filterOptions={(options) => {
                                        const filter = fuzzySearch(options);

                                        return (q) => filter(q).slice(0, 12);
                                    }}
                                />
                            </InputDiv>
                            <div className='hidden-search-neighbor'>
                                <h3>ROI Constraints</h3>
                                <h4>{'<'} 16%</h4>
                            </div>
                            
                            <InputDiv className="input">
                                <h3>Tier 1 ROI / day</h3>
                                <input type="number" autoComplete='off' id="1rewards" placeholder="ROI / day (%)" name='rewards1' onChange={this.handleChange}/>
                            </InputDiv>
                            
                            <InputDiv className="input">
                                <h3>Tier 2 ROI / day</h3>
                                <input type="number" autoComplete='off' id="2rewards" placeholder="ROI / day (%)" name='rewards2' onChange={this.handleChange}/>
                            </InputDiv>
                            
                            <InputDiv className="input">
                                <h3>Tier 3 ROI / day</h3>
                                <input type="number" autoComplete='off' id="3rewards" placeholder="ROI / day (%)" name='rewards3' onChange={this.handleChange}/>
                            </InputDiv>
                            
                            <InputDiv className="input">
                                <h3>Tier 4 ROI / day</h3>
                                <input type="number" autoComplete='off' id="4rewards" placeholder="ROI / day (%)" name='rewards4' onChange={this.handleChange}/>
                            </InputDiv>
                            
                            
                        </InputContainer>
                        <RightSide>

                            <InfoBox className='info-box'>
                                <h3>Fractional Nodes Tips</h3>
                                <p>
                                    Fractional Nodes are inherently unfair, favoring higher node tiers. The reason for this is that compound rates, and APY vary greatly
                                    between tiers. Feel free to read more about fractional nodes on my
                                    <a style={{textDecoration: 'none', fontWeight: 'bold', color: 'black'}} href='https://www.patreon.com/posts/64805928'> Patreon.</a>
                                </p>
                            </InfoBox>
                            <div className='outputs-div'>
                            <ResponsiveContainer minHeight={300} minWidth={300} width="100%" height="100%">
                                <LineChart
                                width={500}
                                height={300}
                                data={this.state.data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 5,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="day" />
                                <YAxis label={{ value: '% yield', angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="tier1" stroke="#8884d8" activeDot={{ r: 4 }} />
                                <Line type="monotone" dataKey="tier2" stroke="#82ca9d" />
                                <Line type="monotone" dataKey="tier3" stroke="#FFBEBC" activeDot={{ r: 4 }} />
                                <Line type="monotone" dataKey="tier4" stroke="#D5AAFF" />
                                </LineChart>
                            </ResponsiveContainer>
                            </div>
                            
                        </RightSide>
                        
                    </Container12>
                </FullContainer>
                <div className='outputs-hidden'>
                <FullContainer sx={{padding: '1rem', marginTop:'1rem'}} >
                    <ResponsiveContainer minHeight={300} minWidth={300} width="100%" height="100%">
                        <LineChart
                        width={500}
                        height={300}
                        data={this.state.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis label={{ value: '% yield', angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="tier1" stroke="#8884d8" activeDot={{ r: 4 }} />
                        <Line type="monotone" dataKey="tier2" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="tier3" stroke="#FFBEBC" activeDot={{ r: 4 }} />
                        <Line type="monotone" dataKey="tier4" stroke="#D5AAFF" />
                        </LineChart>
                    </ResponsiveContainer>
                </FullContainer></div>
                <div className='info-hidden'>
                <FullContainer sx={{padding: '1rem', marginTop:'1rem', marginBot: '2rem'}} >
                    <h3>Fractional Nodes Tips</h3>
                    <p>
                        Fractional Nodes are inherently unfair, favoring higher node tiers. The reason for this is that compound rates, and APY vary greatly
                        between tiers. Feel free to read more about fractional nodes on my
                        <a style={{textDecoration: 'none', fontWeight: 'bold', color: 'black'}} href='https://www.patreon.com/posts/64805928'> Patreon.</a>
                    </p>
                </FullContainer></div>
                
                </motion.div>
            </Body>
            
            
            
        )
    }
}