import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const LPData = [
  {
    title: 'LP Calculator',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'Autocompounder',
    path: '/autocompound',
    cName: 'nav-text'
  },
];
export const SingleAsset = [
  {
    title: 'Direct Shorting',
    path: '/shorting',
    cName: 'nav-text'
  }
];
export const NodeData = [
  {
    title: 'Node Calculator',
    path: '/nodes',
    cName: 'nav-text'
  },
  {
    title: 'Fractional Nodes',
    path: '/fractional-nodes',
    cName: 'nav-text'
  }
];
export const NFTData = [
  {
    title: 'Giveaway EV',
    path: '/giveaway',
    cName: 'nav-text'
  }
];

