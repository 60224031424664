import React, { Component } from 'react'
import { motion } from 'framer-motion';
import { InputContainer2, Body, RightSide, InfoBox, TokenSearchContainer,
     FullContainer, Container12, HeaderContainer, OutputContainer, Hr, Vr,
      InputDiv, Hidden } from '../../components/sharedComponents/styledComponents';
import axios from 'axios';
import { pageVariants, pageTransition } from '../../components/sharedComponents/FramerMotionPrompts/FramerMotionPrompts'
import coinIcon from '../../components/Assets/images/coinIcon.svg'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import AdComponent from '../../components/sharedComponents/AdComponent/AdComponent'

export default class LoanBorrow extends Component<{}, { price: number, lent: number, lentAPY: number,
                                                            borrow: number, borrowAPY: number,LTV: number, days: number, futPrice: number,
                                                            outputs: any[],value1 : string,value2: string,tokenImage1: string,tokenImage2: string,displayPrice1: string,
                                                            displayPrice2: string,tokenName1: string,tokenName2: string,color2: string,color1: string,
                                                            list1: any[], list2: any[] }> {
        
    constructor(props: any){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            price: 0, 
            lent: 0,
            lentAPY: 0,
            borrow: 0,
            borrowAPY: 0,
            days: 0,
            LTV: 0,
            futPrice: 0,
            outputs: [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ],
            value1 : '',
            value2: '',
            tokenImage1: coinIcon,
            tokenImage2: coinIcon,
            displayPrice1: 'N/A',
            displayPrice2: 'N/A',
            tokenName1: 'Token 1',
            tokenName2: 'Coin',
            color2: 'gray',
            color1: 'gray',
            list1: [],
            list2: []
        };
    }
    
        handleChange(event: any) {
        this.setState({ ...this.state, [event.target.name]: event.target.value }, this.math);
        console.log('handle')
        }
    
        handleSearch1(value: any){
        this.setState({ ...this.state, value1: value})
        console.log('handle search')
        }
        handleSearch2(value: any){
        this.setState({ ...this.state, value2: value})
        console.log('handle search')
        }
    
    
        math() {
            console.log('calculating')
            if (this.state.price <= 0 || isNaN(this.state.price) == true){
                this.setState( { ...this.state, outputs : [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ] } )
                return 
            } else if (this.state.lent <= 0 || isNaN(this.state.lent) == true){
                this.setState( { outputs : [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ] } )
                return 
            } else if (this.state.lentAPY <= 0 || isNaN(this.state.lentAPY) == true){
                this.setState( { outputs : [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ] } )
                return 
            } else if (this.state.borrowAPY <= 0 || isNaN(this.state.borrowAPY) == true){
                this.setState( { outputs : [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ] } )
                return 
            } else if (this.state.LTV <= 0 || isNaN(this.state.LTV) == true){
                this.setState( { outputs : [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ] } )
                return 
            } else if (this.state.borrow > 0){
                let APY = this.state.borrowAPY/100
                let days = this.state.days
                let borrowFiat = this.state.borrow*this.state.price
                let liquidationPrice = (this.state.LTV/100)/((this.state.borrow*this.state.price)/this.state.lent) * this.state.price
                let dailyAPR = (((APY+ 1)**(1/365)) - 1) 
                let interest = this.state.borrow * ((1+dailyAPR)**days)
                let profitLoss = borrowFiat - (this.state.futPrice*(interest)) // NOT DONE
                let interestAlone = interest - this.state.borrow

                let outputList = ['$ '+ profitLoss.toFixed(2) , interestAlone.toFixed(3) + ' ' +this.state.tokenName2, '$ ' + liquidationPrice.toFixed(2)]

                if (profitLoss > 0){
                    var plColor = 'green'
                } else if (profitLoss < 0){
                    var plColor = 'red'
                } else {
                    var plColor = 'gray'
                }
                
                let colorList = [plColor, 'red', 'red']
                
                this.setState( {outputs : [ outputList , colorList ] } )
                return 
                
            } else {
                
                this.setState( { outputs : [ ['N/A','N/A','N/A','N/A'] , ['gray','gray','gray','gray'] ] } )
                return 
            }
        }

        async fetchData() {
            try {
                const result = await axios.get(`https://api.coingecko.com/api/v3/search?query=`)
                console.log(result.data);
                return result.data.coins
            } catch (error) {
                console.error(error);
                
            }
        }
        
        
        
        async UNSAFE_componentWillMount() {
            let mounted = true;
            await this.fetchData()
            .then((items: any[]) => {
                if(mounted) {
                this.setState({ list2: items })
                let optionList: any[] = []
                items.forEach(x => optionList.push({name : x.name, value: x.id, key: x.id, id: x.symbol}))
                this.setState({...this.state, list1: optionList})
                console.log('list')
                }
            })
            return () => mounted = false;
        }
    
        GetImageAndPrice1(value: string) {
            console.log(value)
            for (var i = 0; i < this.state.list2.length; i++) {
                if (this.state.list2[i].id == value) {
                    // An item was selected from the list!
                    // yourCallbackHere()
                    this.getPrice1(i);
                    console.log('image')
                    return;
                }
            }
        }
        
        async getPrice1(i:number){
            var id = this.state.list2[i].id
            try {
                const result = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`)
                console.log(result.data);
                this.setState( { ...this.state, displayPrice1 : '$' + result.data[id]['usd'], tokenName1: this.state.list2[i].symbol,
                tokenImage1: this.state.list2[i].large,
                color1: 'black' } )
            } catch (error) {
                console.error(error);
            }  
        } 
        GetImageAndPrice2(value: string) {
            console.log(value)
            
            for (var i = 0; i <  this.state.list2.length; i++) {       
                if (this.state.list2[i].id == value) {
                    // An item was selected from the list!
                    // yourCallbackHere()
                    this.getPrice2(i);
                    break;
                }
            }
    
        }
                
        async getPrice2(i:number){
            var id = this.state.list2[i].id
            try {
                const result = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`)
                console.log(result.data);
                this.setState( {...this.state, displayPrice2 : '$' + result.data[id]['usd'], tokenName2: this.state.list2[i].symbol,
                tokenImage2: this.state.list2[i].large,
                color2: 'black' } )
            } catch (error) {
                console.error(error);
            }  
        } 
    
    
        render() {
        return (
            <Body className='calc-body'>
            <motion.div 
            initial='initial'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition}
        
            >
            <FullContainer className='full-container'>
                <div className='header'>
                <HeaderContainer>
                    <img style={{transition: '550ms'}} src={this.state.tokenImage1} width='100px' alt="token1-logo" />
                    <div className="headerContent">
                        <h3 className="pricebot">{this.state.tokenName1} Price</h3>
                        <h1 style={{transition: '550ms', color: this.state.color1}} id="pricehere">{this.state.displayPrice1}</h1>
                    </div>
                    <div></div>
                    <img src={this.state.tokenImage2} width='100px' alt="token1-logo" />
                    <div className="headerContent">
                        <h3 className="pricebot">{this.state.tokenName2} Price</h3>
                        <h1 style={{transition: '550ms',color: this.state.color2}} id="pricehere">{this.state.displayPrice2}</h1>
                    </div>
                </HeaderContainer>
                </div>
                <div className='title'>
                    <img style={{width: '45px'}} src={this.state.tokenImage1} />
                    <h2>Direct Shorting</h2>
                    <img style={{width: '45px'}} src={this.state.tokenImage2} />
                </div>
                <Hr></Hr>
                
                <Container12 className="contents"> 
                    
                    <InputContainer2 className="container">
                        
                        <div className='search'>
                        <InputDiv className="input search">
                            <h3>Lent Asset</h3>
                            <SelectSearch
                                value={this.state.value1}
                                options={this.state.list1}
                                search
                                onChange={x => {this.GetImageAndPrice1(String(x)); this.handleSearch1(String(x))}}
                                placeholder="Find Coin"
                                filterOptions={(options) => {
                                    const filter = fuzzySearch(options);
    
                                    return (q) => filter(q).slice(0, 12);
                                }}
                            />
                        
                        </InputDiv></div>
                        <div className='search'>
                        <InputDiv className="input search">
                            <h3>Borrowed Asset</h3>
                            <SelectSearch
                                options={this.state.list1}
                                search
                                onChange={x => {this.GetImageAndPrice2(String(x)); this.handleSearch2(String(x))}}
                                value={this.state.value2}
                                placeholder="Find Coin"
                                filterOptions={(options) => {
                                    const filter = fuzzySearch(options);
    
                                    return (q) => filter(q).slice(0, 15);
                                }}
                            />
    
                        </InputDiv></div>
                        <InputDiv className="input">
                            <h3>Lent Amount</h3>
                            <input type="number" name="lent" autoComplete='off' placeholder='Lending Amount ($)' onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>Lent APY (%)</h3>
                            <input type="number" name="lentAPY" autoComplete='off' placeholder='Lending APY (%)' onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>{this.state.tokenName2} Borrowed</h3>
                            <input type="number" name="borrow" autoComplete='off' placeholder={'Amount ' + this.state.tokenName2 + ' Borrowed'} onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>Borrow APY (%)</h3>
                            <input type="number" name="borrowAPY" autoComplete='off' placeholder="Borrow APY (%)" onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>Initial Price</h3>
                            <input type="number" name="price" autoComplete='off' placeholder={'Initial Price of ' + this.state.tokenName2 + ' ($)'} onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>Liquidation LTV</h3>
                            <input type="number" name="LTV" autoComplete='off' placeholder="LTV (%) " onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>Future Price</h3>
                            <input type="number" name="futPrice" autoComplete='off' placeholder={'Future Price of ' + this.state.tokenName2 + ' ($)'} onChange={this.handleChange}/>
                        </InputDiv>
                        <InputDiv className="input">
                            <h3>Days Out</h3>
                            <input type="number" name="days" autoComplete='off' placeholder="Days planned to hold" onChange={this.handleChange}/>
                        </InputDiv>
                        
                    </InputContainer2> 
                    <RightSide>
                        <InfoBox className='info-box'>
                            <h3>Loan and Borrow Direct Shorting</h3>
                            <p>
                                The basic strategy for this shorting method is loaning a stablecoin, and borrowing ~ 40% of the lent amount in a short position of your
                                choice. Some things to look out for is liquidation price, and borrow APY which if held too long can result in quite a bit of interest owed. Click <a href='https://www.youtube.com/watch?v=1IQS-SH46OQ'>here</a> for 
                                more info on Direct Shorting.
                            </p>
                        </InfoBox>
                        <div className='outputs-div'>
                        <OutputContainer className='outputs'>
                            <div className="output">
                                <h3>Profit & Loss</h3>
                                <h2 style={{color: this.state.outputs[1][0]}}>{ this.state.outputs[0][0] }</h2>
                            </div>
                            <div className="output">
                                <h3>Liquidation Price</h3>
                                <h2 style={{color: this.state.outputs[1][2]}}>{ this.state.outputs[0][2] }</h2>
                            </div>
                            <div className="output">
                                <h3>Borrow Interest</h3>
                                <h2 style={{color: this.state.outputs[1][1]}}>{ this.state.outputs[0][1] }</h2>
                            </div>
                            
                        </OutputContainer></div>
                    </RightSide>
                </Container12>
            </FullContainer>
            <div className='outputs-hidden'>
            <FullContainer sx={{padding: '1rem', marginTop:'1rem'}} >
                <div className="output">
                    <h3>Profit & Loss</h3>
                    <h2 style={{color: this.state.outputs[1][0]}}>{ this.state.outputs[0][0] }</h2>
                </div>
                <div className="output">
                    <h3>Liquidation Price</h3>
                    <h2 style={{color: this.state.outputs[1][2]}}>{ this.state.outputs[0][2] }</h2>
                </div>
                <div className="output">
                    <h3>Borrow Interest</h3>
                    <h2 style={{color: this.state.outputs[1][1]}}>{ this.state.outputs[0][1] }</h2>
                </div>
            </FullContainer></div>
            <div className='info-hidden'>
            <FullContainer sx={{padding: '1rem', marginTop:'1rem', marginBot: '2rem'}} >
                <h3>Loan and Borrow Direct Shorting</h3>
                <p>
                    The basic strategy for this shorting method is loaning a stablecoin, and borrowing ~ 40% of the lent amount in a short position of your
                    choice. Some things to look out for is liquidation price, and borrow APY which if held too long can result in quite a bit of interest owed. Click <a style={{textDecoration: 'none', fontWeight: 'bold', color: 'black'}} href='https://www.youtube.com/watch?v=1IQS-SH46OQ'>here</a> for 
                    more info on Direct Shorting.
                </p>
            </FullContainer></div>
            
            </motion.div>
        </Body>
        )
    }
}
