import React, {useEffect} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ReactDOM from 'react-dom';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import Navbar from './components/Navbar/Navbar';
import NodeCalc from './pages/NodeCalc/NodeCalc';
import FractionalNodes from './pages/FractionalNodes/FractionalNodes';
import Giveaway from './pages/Giveaway/Giveaway';
import LPCalc from './pages/LPCalc/LPCalc';
import Autocompounder from './pages/Autocompounder/Autocompounder';
import LoanBorrow from './pages/LoanBorrow/LoanBorrow';
import { TRACKING_ID } from './TrackingID';
import '../src/components/Assets/css/styles.css';

ReactGA.initialize(TRACKING_ID)

export default function App () {
  const location = useLocation()
  
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, []);

  return (
    <>
      <Navbar />
      <main style={{overflowX: 'hidden'}}>
      <AnimatePresence exitBeforeEnter >
        <Routes location={location} key={location.pathname} > 
          <Route path="/nodes" element={<NodeCalc />} />
          <Route path="/fractional-nodes" element={<FractionalNodes />} />
          <Route path="/giveaway" element={<Giveaway />} />    
          <Route path="/" element={<LPCalc />} />
          <Route path="/autocompound" element={<Autocompounder />} />
          <Route path="/shorting" element={<LoanBorrow />} />
        </Routes>
      </AnimatePresence>
      </main>
    </>
  );
}
